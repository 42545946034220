<template>
  <div>
    <h1><strong>Error 404: Page not found</strong></h1>
  </div>
</template>

<script>
export default {
  name: 'error404_page',
}
</script>
<style scoped>
h1 {
  font-size: 32px;
  text-align: center;
  color: white;
}
</style>