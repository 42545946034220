<template>
  <b-navbar shadow fixed-top>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <svg
          width="36"
          height="36"
          viewBox="0 0 260 260"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#E75E45">
            <path
              d="M152.7 48.5c-6.8-2.5-14.1-4.1-21.8-4.4-12.7-.6-24.8 2.2-35.4 7.6-.6.3-1.3.6-2 1v36.4c0 .5 0 2.4-.3 4-.7 3.7-2.9 7-6 9.1-2.6 1.8-5.6 2.6-8.8 2.5-.6 0-1.3-.1-1.9-.2-1.6-.3-3.3-.9-3.8-1.1-1.4-.5-21.8-8.4-31.6-12.2-1.2-.5-2.2-.9-3-1.2-11.7 9.9-24 21.7-35.5 35.6-.1.1-.6.7-.7.8-1.5 2.1-1.6 5.1 0 7.4 1.2 1.7 3.1 2.7 5 2.8 1.3.1 2.7-.3 3.9-1.1.1-.1.2-.2.4-.3 12.2-8.8 25.6-15.9 39.8-21.6 1-.5 2.2-.8 3.3-.7 1.6.1 3.1.7 4.3 1.9 2.3 2.3 2.4 6 .5 8.5-.8 1.2-1.5 2.4-2.2 3.6-7.6 12.4-13.7 25.9-18.3 40-.1.4-.2.7-.3 1.1v.1c-.4 1.7-.1 3.5 1 5 1.2 1.7 3.1 2.7 5.1 2.8 1.4.1 2.7-.3 3.9-1.1.5-.4 1-.8 1.4-1.3.1-.2.3-.4.4-.6 5-7.1 10.5-13.8 16.4-20 26.3-28.2 61.2-48.1 100.3-55.9.3-.1.6-.1.9-.1 2.2.1 3.9 2 3.8 4.2-.1 1.9-1.4 3.3-3.2 3.7-36.3 7.7-101.7 50.8-78.8 113.4.4 1 .7 1.6 1.2 2.5 1.2 1.7 3.1 2.7 5 2.8 1.1 0 4.2-.3 6.1-3.7 3.7-7 10.7-14.8 30.9-23.2 56.3-23.3 65.6-56.6 66.6-77.7v-1.2c.9-31.4-18.6-58.8-46.6-69.2zm-56.5 165C91 198 91.5 183 97.6 168.7c11.7 18.9 32.1 20.5 32.1 20.5-20.9 8.7-29.1 17.3-33.5 24.3z"
            ></path>
            <path
              d="M1.1 50.6c.1.2.3.4.4.5 5.3 7.2 11.3 13.5 17.8 19.1.1.1.2.1.2.2 4.2 3.6 12.2 8.8 18 10.9 0 0 36.1 13.9 38 14.7.7.3 1.7.6 2.2.7 1.6.3 3.3 0 4.8-1s2.4-2.5 2.7-4.1c.1-.6.2-1.6.2-2.3V48.5c.1-6.2-1.9-15.6-3.7-20.8 0-.1-.1-.2-.1-.3-2.8-8.1-6.6-16-11.4-23.5l-.3-.6-.1-.1c-2-2.8-6-3.5-8.9-1.5-.5.3-.8.7-1.2 1.1-.3.4-.5.7-.8 1.1-6.4 9.3-9 20.6-7.3 31.7-3.3 1.7-6.8 4-7.2 4.3-.4.3-3.9 2.7-6.6 5.2-9.7-5.5-21.3-7.2-32.2-4.6-.4.1-.9.2-1.3.3-.5.2-1 .4-1.4.7-2.9 2-3.7 5.9-1.8 8.9v.2zm63.5-40.1c3.4 5.7 6.3 11.6 8.6 17.8-4.6.8-9.1 2-13.5 3.6-.6-7.5 1.1-14.9 4.9-21.4zM31.5 51.3c-3.2 3.5-5.9 7.3-8.3 11.3-4.9-4.3-9.4-9.2-13.5-14.4 7.5-1.3 15-.2 21.8 3.1z"
            ></path>
          </g>
        </svg>
        <strong>Waifu Wars</strong>
      </b-navbar-item>
      <div class="is-hidden-desktop" tag="div">
        <div class="buttons">
          <a v-if="!token" class="button is-primary" @click="modal">
            Login
          </a>
          <a v-else class="button is-primary" @click="logout"> Logout </a>
        </div>
      </div>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Vote
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/results' }">
        Results
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/memes' }">
        Memes
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/fantasyleague' }">
        Fantasy League
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/winners' }">
        Previous Winners
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item class="is-hidden-mobile" tag="div">
        <div class="buttons">
          <a v-if="!token" class="button is-primary" @click="modal">
            Login
          </a>
          <a v-else class="button is-primary" @click="logout"> Logout </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
export default {
  props: ["token"],
  data() {
    return {
      isComponentModalActive: true,
      IsLogging: false,
      password: "",
      email: "",
    };
  },
  methods: {
    modal() {
      this.$emit("modal");
    },
    logout() {
      localStorage.clear();
      this.$emit("checktoken");
    },
  },
};
</script>
<style scoped>
.router-link-exact-active {
  color: #c1c1c4;
  background-color: var(--navbar-focus-color);
}
.is-hidden-desktop {
  margin: 0;
  margin-left: auto;
}
.is-hidden-desktop .button {
  margin-bottom: 0px;
}
.is-hidden-desktop .buttons {
  height: 100%;
}
.navbar-brand > .navbar-item {
  color: #fd755c;
  background-color: var(--navbar-background-color);
}
</style>
