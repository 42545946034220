var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{attrs:{"type":"is-toggle-rounded"}},[_c('b-tab-item',{attrs:{"label":"Waifu"}},[(_vm.rounds.find((x) => x.tier === 'Waifu'))?_c('form',{staticClass:"grid"},[_c('div',{staticClass:"grid-items"},[_c('b-radio',{attrs:{"name":"opponents","native-value":_vm.rounds.find((x) => x.tier === 'Waifu').opp1Id.id},model:{value:(_vm.votes.Waifu.id),callback:function ($$v) {_vm.$set(_vm.votes.Waifu, "id", $$v)},expression:"votes.Waifu.id"}},[_c('img',{attrs:{"src":'https://api.waifuwars.madao-king.xyz/' +
            _vm.rounds.find((x) => x.tier === 'Waifu').opp1Id.image}}),_c('p',{staticClass:"opp_name"},[_vm._v(" "+_vm._s(_vm.rounds.find((x) => x.tier === "Waifu").opp1Id.name)+" ")])]),_c('b-radio',{attrs:{"native-value":_vm.rounds.find((x) => x.tier === 'Waifu').opp2Id.id},model:{value:(_vm.votes.Waifu.id),callback:function ($$v) {_vm.$set(_vm.votes.Waifu, "id", $$v)},expression:"votes.Waifu.id"}},[_c('img',{attrs:{"src":'https://api.waifuwars.madao-king.xyz/' +
            _vm.rounds.find((x) => x.tier === 'Waifu').opp2Id.image}}),_c('p',{staticClass:"opp_name"},[_vm._v(" "+_vm._s(_vm.rounds.find((x) => x.tier === "Waifu").opp2Id.name)+" ")])])],1),(_vm.token && !_vm.votes.Waifu.AlreadyVoted)?_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"Submit your vote"},on:{"click":function($event){return _vm.SubmitVote('Waifu')}}}):(_vm.token)?_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"You already voted","disabled":""}}):_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"You need to log in","disabled":""}})]):_c('h1',{staticClass:"notournament"},[_vm._v("No tournament for today")])]),_c('b-tab-item',{attrs:{"label":"Husbando"}},[(_vm.rounds.find((x) => x.tier === 'Husbando'))?_c('form',{staticClass:"grid"},[_c('div',{staticClass:"grid-items"},[_c('b-radio',{attrs:{"name":"opponents","native-value":_vm.rounds.find((x) => x.tier === 'Husbando').opp1Id.id},model:{value:(_vm.votes.Husbando.id),callback:function ($$v) {_vm.$set(_vm.votes.Husbando, "id", $$v)},expression:"votes.Husbando.id"}},[_c('img',{attrs:{"src":'https://api.waifuwars.madao-king.xyz/' +
            _vm.rounds.find((x) => x.tier === 'Husbando').opp1Id.image}}),_c('p',{staticClass:"opp_name"},[_vm._v(" "+_vm._s(_vm.rounds.find((x) => x.tier === "Husbando").opp1Id.name)+" ")])]),_c('b-radio',{attrs:{"name":"opponents","native-value":_vm.rounds.find((x) => x.tier === 'Husbando').opp2Id.id},model:{value:(_vm.votes.Husbando.id),callback:function ($$v) {_vm.$set(_vm.votes.Husbando, "id", $$v)},expression:"votes.Husbando.id"}},[_c('img',{attrs:{"src":'https://api.waifuwars.madao-king.xyz/' +
            _vm.rounds.find((x) => x.tier === 'Husbando').opp2Id.image}}),_c('p',{staticClass:"opp_name"},[_vm._v(" "+_vm._s(_vm.rounds.find((x) => x.tier === "Husbando").opp2Id.name)+" ")])])],1),(_vm.token && !_vm.votes.Husbando.AlreadyVoted)?_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"Submit your vote"},on:{"click":function($event){return _vm.SubmitVote('Husbando')}}}):(_vm.token)?_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"You already voted","disabled":""}}):_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"You need to log in","disabled":""}})]):_c('h1',{staticClass:"notournament"},[_vm._v("No tournament for today")])]),_c('b-tab-item',{attrs:{"label":"Meme"}},[(_vm.rounds.find((x) => x.tier === 'Meme'))?_c('form',{staticClass:"grid"},[_c('div',{staticClass:"grid-items"},[_c('b-radio',{attrs:{"name":"opponents","native-value":_vm.rounds.find((x) => x.tier === 'Meme').opp1Id.id},model:{value:(_vm.votes.Meme.id),callback:function ($$v) {_vm.$set(_vm.votes.Meme, "id", $$v)},expression:"votes.Meme.id"}},[_c('img',{attrs:{"src":'https://api.waifuwars.madao-king.xyz/' +
            _vm.rounds.find((x) => x.tier === 'Meme').opp1Id.image}}),_c('p',{staticClass:"opp_name"},[_vm._v(" "+_vm._s(_vm.rounds.find((x) => x.tier === "Meme").opp1Id.name)+" ")])]),_c('b-radio',{attrs:{"name":"opponents","native-value":_vm.rounds.find((x) => x.tier === 'Meme').opp2Id.id},model:{value:(_vm.votes.Meme.id),callback:function ($$v) {_vm.$set(_vm.votes.Meme, "id", $$v)},expression:"votes.Meme.id"}},[_c('img',{attrs:{"src":'https://api.waifuwars.madao-king.xyz/' +
            _vm.rounds.find((x) => x.tier === 'Meme').opp2Id.image}}),_c('p',{staticClass:"opp_name"},[_vm._v(" "+_vm._s(_vm.rounds.find((x) => x.tier === "Meme").opp2Id.name)+" ")])])],1),(_vm.token && !_vm.votes.Meme.AlreadyVoted)?_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"Submit your vote"},on:{"click":function($event){return _vm.SubmitVote('Meme')}}}):(_vm.token)?_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"You already voted","disabled":""}}):_c('input',{staticClass:"button is-primary",attrs:{"type":"button","value":"You need to log in","disabled":""}})]):_c('h1',{staticClass:"notournament"},[_vm._v("No tournament for today")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }